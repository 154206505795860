<template>
	<main v-if="visible" class="layer-popup-container">
		<div class="layer-popup" id="layerPopupProducts">
			<div>
				<div class="popup-wrapper">
					<header class="popup-header">
						<button type="button" class="btn-close" @click="clickClose">
							<i class="icon-close">팝업닫기</i>
						</button>
					</header>
					<div class="popup-body" data-fill="true">
						<div class="popup-product">
							<div class="popup-product-content">
								<div class="popup-product-img">
									<img :src="item.image" alt="" />
								</div>
								<div class="popup-product-info">
									<div class="popup-product-info__desc">
										<span class="info-desc-brand">{{ item.company }}</span>
										<span class="info-desc-title">{{ item.title }}</span>
										<span class="info-desc-price">{{ Number(item.price).toLocaleString() }}</span>
									</div>
									<div v-if="like || rate" class="popup-product-info__rating">
										<button type="button" class="button-rate" @click.prevent.stop="clickRate">
											<i class="icon">
												<!-- <icon name="icon-small-star" /> -->
												<img src="@/assets/img/products/star-full-prm.svg" alt="별점" v-if="item.myRate > 0" />
												<img src="@/assets/img/products/star-fill-o.svg" alt="별점" v-else />
											</i>
											<span class="star-rate">{{ item.rate }}</span>
											<span class="sr-only">별점</span>
											<!-- 별점선택 툴팁 -->
											<div v-if="visibleRate" class="popup-product-info__rating-box">
												<div class="rating-box-header">
													<span class="rating-box-header-title">별점선택</span>
												</div>
												<button type="button" class="btn-close" @click.prevent.stop="closeRate()">
													<i class="icon-close">닫기</i>
												</button>
												<div class="rating-box-body">
													<div class="rating-box-select" @mousedown="startDrag" @mousemove="drag" @mouseup="stopDrag" @mouseleave="stopDrag">
														<button v-for="index in 5" :key="index" @click="check(index)">
															<i class="icon">
																<img src="@/assets/img/products/star-full.svg" alt="별점 선택" v-if="index <= item.myRate" />
																<img src="@/assets/img/products/star-fill-o.svg" alt="별점" v-else />
															</i>
														</button>
													</div>
													<div class="rating-box-count">
														<span>{{ item.myRate }}</span>
														/
														<span>5</span>
													</div>
												</div>
											</div>
											<!-- 툴팁 end -->
										</button>
										<button type="button" class="button-like" @click="clickLike">
											<i class="icon">
												<!-- <icon name="icon-small-heart" /> -->
												<img src="@/assets/img/products/heart-full-prm.svg" alt="찜하기" v-if="item.like" />
												<img src="@/assets/img/products/heart-fill-o.svg" alt="찜하기" v-else />
											</i>
											<span class="like-count">{{ Number(item.likeCount).toLocaleString() }}</span>
											<span class="sr-only">찜하기</span>
										</button>
									</div>
								</div>
							</div>
							<p class="popup-product-info__detail" v-html="item.desc"></p>
						</div>
					</div>
					<div class="popup-footer" v-if="showBuyBtn">
						<div class="popup-product-button-order">
							<a class="button-order" :href="item.siteUrl" target="_blank">
								<span>{{ this.txtBuy }}</span>
							</a>
						</div>
					</div>
					<div class="popup-footer" v-else>
						<div class="popup-product-button-order">
							<a class="button-order" href="javascript:;" disabled>
								<span>판매 기간이 아닙니다.</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_UPDATE_CAMPN_MANPRD_FAVR, ACT_UPDATE_CAMPN_MANPRD_STAR } from '@/store/_act_consts';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_CONFIRM, MUT_SHOW_ALERT } from '@/store/_mut_consts';

import { isSuccess, dateToDateFormat } from '@/assets/js/utils';
import { countDDay } from '@/assets/js/DateUtils';

import Icon from '@/components/common/Icon';
export default {
	name: 'ProductsModal',
	props: {
		visible: Boolean,
		item: Object,
		parentClickEvntNm: String,
		like: {
			type: Boolean,
			default: true,
		},
		rate: {
			type: Boolean,
			default: true,
		},
	},
	components: { Icon },
	data: () => ({
		index: 0,
		isDrag: false,
		visibleRate: false,
	}),
	computed: {
		...mapGetters('common', ['isMobile', 'commonCode', 'campn']),
		...mapGetters('auth', ['session', 'isAuth']),
		txtBuy() {
			return !this.item.isEng ? '구매하기' : 'Buy';
		},
		showBuyBtn() {
			const nowDt = dateToDateFormat(new Date(), 'yyyy-MM-dd') + ' 00:00';
			const startDt = this.item.useCampnDurYn ? this.campn.strtDt : this.item.ntslStrtDt;
			const endDt = this.item.useCampnDurYn ? this.campn.endDt : this.item.ntslEndDt;



		 	return !(countDDay(startDt, nowDt) < 0 || countDDay(nowDt, endDt) < 0);
		}
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		clickClose() {
			this.visibleRate = false;
			this.$emit('closeModal');
		},
		clickRate() {
			if (!this.chkCampnDuration(`행사 기간이 아닙니다.`)) return false;
			this.visibleRate = !this.visibleRate;
		},
		closeRate() {
			this.visibleRate = false;
		},
		clickLike() {
			// this.itemLike = !this.itemLike;
			//this.$emit(this.parentClickEvntNm, "F");
			this.setFavr();
		},
		check(index) {
			// this.index = index;
			// this.rateCount = index + 1;
			//this.$emit(this.parentClickEvntNm, "S", index);
			this.setStar(index);
		},
		startDrag(event) {
			// this.isDrag = true;
			// this.updateRate(event);
		},
		drag(event) {
			if (this.isDrag) {
				this.undateRate(event);
			}
		},
		stopDrag() {
			this.isDrag = false;
		},
		undateRate(event) {
			const boundingRect = this.$el.getBoundingClientRect();
			const x = event.clientX - boundingRate.left;
			const width = boundingRect.width;
			const newRate = Math.ceil((x / width) * 5);

			if (newRate >= 1 && newRate <= 5) {
				this.index = newRate - 1;
				// this.rateCount - newRate;
			}
		},
		chkLogin(title) {
			if (typeof this.session.userId == 'undefined') {
				const param = {
					title: title,
					html: true,
					showCancelButton: false,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
					nofunc: () => {},
				};
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, param);
				return false;
			}

			return true;
		},
		chkCampnDuration(msg) {

			const nowDt = dateToDateFormat(new Date(), 'yyyy-MM-dd') + ' 00:00';
			
			// 기간 체크
			if (countDDay(this.campn.strtDt, nowDt) < 0 || countDDay(nowDt, this.campn.endDt) < 0) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: msg,
					rightNow: true,
				});
				return false;
			}
			return true;
		},
		// 찜하기 설정
		setFavr() {
			if (!this.chkCampnDuration(`이벤트 기간이 아닙니다.`)) return false;
			if (!this.chkLogin('로그인 상태에서 찜할 수 있습니다. 로그인하시겠습니까?')) return false;

			// api 호출
			this.$store
				.dispatch(`manprd/${ACT_UPDATE_CAMPN_MANPRD_FAVR}`, {
					manprdId: this.item.id,
					params: {
						manprdId: this.item.id,
						score: 0,
						useYn: !this.item.like ? 'Y' : 'N',
					},
				})
				.then((res) => {
					this.getFavrAlter(res);
				})
				.catch((e) => {
					console.error(e);
				});
		},
		// 별점하기
		setStar(score) {
			if (!this.chkCampnDuration(`행사 기간이 아닙니다.`)) return false;
			if (!this.chkLogin('로그인 상태에서 별점 할 수 있습니다. 로그인하시겠습니까?')) return false;

			// api 호출
			this.$store
				.dispatch(`manprd/${ACT_UPDATE_CAMPN_MANPRD_STAR}`, {
					manprdId: this.item.id,
					params: {
						manprdId: this.item.id,
						score: score,
						useYn: 'Y',
					},
				})
				.then((res) => {
					if (isSuccess(res)) {
						// 최신 모달 정보를 가져온다
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
							title: '별점이 추가되었습니다',
							yesfunc: () => {
								this.$emit(this.parentClickEvntNm, 'S', score);
							},
							rightNow: true,
						});
						
					} else {
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
							title: '별점 추가 실패 하였습니다.',
							yesfunc: () => {},
							rightNow: true,
						});
					}
				})
				.catch((e) => {
					console.error(e);
				});
		},
		getFavrAlter(res) {
			if (isSuccess(res)) {
				this.item.like = !this.item.like;
				let text = this.item.like ? '찜하기 등록되었습니다' : '찜하기 해제되었습니다.';
				//this.$toast.open(text);

				// 마케팅 동의 여부 체크
				if(this.item.like && this.campn.prvcMktAgreYn != 'Y') {
					this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
						title: '찜하기 등록되었습니다. 찜하기 이벤트에 참여하시겠습니까?',
						showCancelButton: true,
						yesfunc: () => {this.$router.push({name:'EventDibs'});},
						nofunc: () => {this.$emit(this.parentClickEvntNm, 'F');}
					});
				} else {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: text,
						yesfunc: () => {
							this.$emit(this.parentClickEvntNm, 'F');
						},
						rightNow: true,
					});
				}
			} else {
				let text = !this.item.like ? '찜하기 등록 실패 하였습니다.' : '찜하기 해제 실패 하였습니다.';
				if(res && res.result && res.result.number > 900) text = res.result.message;

				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: text,
					yesfunc: () => {},
					rightNow: true,
				});
			}
		}
	},
};
</script>
