export const timeStampToDateTimeString = (param) => {
    const paramDate = new Date(param);
    if (param == null || param.length === 0 || isNaN(paramDate.getTime()) || paramDate.getFullYear() <= 1970)
    {
        return "";
    }
    const year = paramDate.getFullYear().toString();
    const month = (paramDate.getMonth() + 1).toString();
    const date = paramDate.getDate().toString();
    const hour = paramDate.getHours().toString();
    const minute = paramDate.getMinutes().toString();
    const second = paramDate.getSeconds().toString();
    return year + "-" + month.padStart(2, "0") + "-" + date.padStart(2, "0") + " " + hour.padStart(2, "0") + ":" + minute.padStart(2, "0") + ":" + second.padStart(2, "0");
};

export const DateTimeFormat = "yyyy-MM-dd HH:mm:ss";
export const DateFormat = "yyyy-MM-dd";
export const TimeFormat = "HH:mm:ss";

export const DateFormatPlaceholder = {year : "yyyy", month: "mm", day : "dd"};
export const TimeFormatPlaceholder = {hour : "hh", minute: "mm", second : "ss"};

// varchar(6)시분초 -> Date time set
export const stringtimeToDateTime = (param) => {
    if (param === null || param === "" || param === undefined){
        return "";
    }
    if(typeof(param) === "string"){
        const date = new Date();
        const hour = param.substring(0,2);
        const minute = param.substring(2,4);
        const second = param.substring(4,6);
        date.setHours(hour);
        date.setMinutes(minute);
        date.setSeconds(second);
        return date;
    }
    else if(typeof(param) === "object"){
        return param;
    }
    else{
        return "";
    }
}

// varchar(6)시분초 -> Date time String set
export const stringtimeToDateTimeString = (param) => {
    if (param === null || param === "" || param === undefined){
        return "";
    }
    const date = new Date();
    const hour = param.substring(0,2);
    const minute = param.substring(2,4);
    const second = param.substring(4,6);
    date.setHours(hour);
    date.setMinutes(minute);
    date.setSeconds(second);
    return date.toString();
}

// varchar(6)시분초 -> hh:mm:ss set
export const stringtimeToTime = (param) => {
    if (param === null || param === "" || param === undefined){
        return "";
    }
    const hour = param.substring(0,2);
    const minute = param.substring(2,4);
    const second = param.substring(4,6);


    return hour + ":" + minute + ":" + second;
}

// Date -> yyyy-mm-dd set
export const timeStampToDateString = (param) => {
    if (param === null || param === "" || param === undefined){
        return "";
    }
    const paramDate = new Date(param);
    const year = paramDate.getFullYear().toString();
    const month = (paramDate.getMonth() + 1).toString();
    const date = paramDate.getDate().toString();
    return year + "-" + month.padStart(2,"0") + "-" + date.padStart(2, "0");
}

// Date -> yyyy-mm-dd set
export const dateToDateString = (param) => {
    if (param === null || param === "" || param === undefined){
        return "";
    }
    const year = param.getFullYear().toString();
    const month = (param.getMonth() + 1).toString();
    const date = param.getDate().toString();
    return year + "-" + month.padStart(2,"0") + "-" + date.padStart(2, "0");
}

// Date -> hh:mm:ss set
export const timeStampToTimeString = (param) => {
    if (param === null || param === "" || param === undefined){
        return "";
    }
    const paramDate = new Date(param);
    const hour = paramDate.getHours().toString();
    const minute = paramDate.getMinutes().toString();
    const second = paramDate.getSeconds().toString();
    return hour.padStart(2, "0") + ":" + minute.padStart(2, "0") + ":" + second.padStart(2, "0");
}

export const getAddDay = (dateObj, numDays) => {
    let retDate = new Date(dateObj);
    retDate.setDate(dateObj.getDate() + numDays);
    return retDate;
}
export const getMinusDay = (dateObj, numDays) => {
    let retDate = new Date(dateObj);
    retDate.setDate(dateObj.getDate() - numDays);
    return retDate;
}

export const getDate = (dateObj, cond) => {
    if (dateObj === null){
        return dateObj;
    }
    else if (cond === "bgn"){
        dateObj.setHours(0,0,0,0);
        return dateObj;
    }
    else if (cond === "end"){
        dateObj.setHours(23,59,59,0);
        return dateObj;
    }
    else if (cond === "initBgn"){
        return new Date(dateObj.getFullYear(), dateObj.getMonth(), 1, 0, 0, 0, 0);
    }
    else if (cond === "initEnd"){
        return new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0, 23, 59, 59, 0);
    }
    else{
        return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate());
    }
}

export const numbersOnlyTimeStampToDateTimeString = (param) => {
    const paramDate = new Date(param);
    if (param == null || param.length === 0 || isNaN(paramDate.getTime()) || paramDate.getFullYear() <= 1970)
    {
        return "";
    }
    const year = paramDate.getFullYear().toString();
    const month = (paramDate.getMonth() + 1).toString();
    const date = paramDate.getDate().toString();
    const hour = paramDate.getHours().toString();
    const minute = paramDate.getMinutes().toString();
    const second = paramDate.getSeconds().toString();
    return year + "" + month.padStart(2, "0") + "" + date.padStart(2, "0") + "" + hour.padStart(2, "0") + "" + minute.padStart(2, "0") + "" + second.padStart(2, "0");
};

// D-Day 문자
export const countDDayToString = (bgngCond, endCond) => {

    let strtDate;
    (bgngCond === null || bgngCond === "" || typeof bgngCond === "undefined")
    ? strtDate = new Date()
    : strtDate = new Date(bgngCond);

    const endDate = new Date(endCond);

    const mnsDate = endDate.getTime() - strtDate.getTime();
    const calcDate = mnsDate / (1000 * 3600 * 24);

    let dDay;
    (calcDate < 0)
    ? dDay = "D+" + Math.floor(Math.abs(calcDate))
    : dDay = "D-" + Math.floor(calcDate);

    return dDay;
};

// D-Day 일수
export const countDDay = (bgngCond, endCond) => {

    let strtDate;
    (bgngCond === null || bgngCond === "" || typeof bgngCond === "undefined")
    ? strtDate = new Date()
    : strtDate = new Date(bgngCond);

    const endDate = new Date(endCond);

    const mnsDate = endDate.getTime() - strtDate.getTime();
    const calcDate = mnsDate / (1000 * 3600 * 24);

    return Math.floor(calcDate);
};
