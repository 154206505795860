<template>
	<main id="container" class="page-container">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="Featured Products" />
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<div class="products-list">
					<products-item v-for="(item, idx) in manprdList" :key="item.manprdId" :id="item.id" :item="item" type="F" :like="false" :rate="false" parent-like-evnt-nm="likeClicked" @likeClicked="handleClickLike" parent-click-evnt-nm="modalClicked" @modalClicked="handleClickModal" />
				</div>
			</div>
		</div>
		<div class="pagination-wrap">
			<portal-pagination page-name="Category" :page-no="pageNo" :page-size="pageSize" :page-list-size="pageListSize" :total-count="totalCount" :query="queries" :parent-method-name="pagingMethodName" @getManprdList="getManprdList"></portal-pagination>
		</div>
		<products-modal :visible="visibleModal" @closeModal="visibleModal = false" :item="modalItem" :like="false" :rate="false" parent-click-evnt-nm="clickEvnt" @clickEvnt="handleClickModalEvnt" />
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_GET_CAMPN_MANPRD_LIST, ACT_UPDATE_CAMPN_MANPRD_FAVR, ACT_UPDATE_CAMPN_MANPRD_STAR } from '@/store/_act_consts';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_CONFIRM, MUT_SHOW_ALERT } from '@/store/_mut_consts';

import { getItems, lengthCheck, getCheckItems, setPaging, tryResCallback, queryToValue, isSuccess, timestampToDateFormat } from '@/assets/js/utils';

import PortalPagination from '@/components/PortalPaginationRoute';

import PageHeader from '@/components/content/PageHeader';
import ProductsModal from '@/components/content/ProductsModal';
import ProductsItem from '@/components/content/ProductsItem';
export default {
	name: 'Popular',
	components: { PageHeader, ProductsItem, ProductsModal, PortalPagination },
	data: () => ({
		visibleModal: false,
		modalId: 0,
		modalType: '',
		manprdList: [],
		pagingMethodName: 'getManprdList',
		pageNo: 1,
		pageSize: 10,
		pageListSize: 10,
		totalCount: 0,
	}),
	computed: {
		...mapGetters('common', ['isMobile', 'commonCode', 'campn']),
		...mapGetters('auth', ['session', 'isAuth']),
		campnId() {
			return this.campn.campnId;
		},
		modalItem() {
			if (this.modalId > 0) return this.manprdList.find((i) => i.id == this.modalId);

			return null;
		},
		queries() {
			const result = {};
			result.searchName = this.searchName;
			result.ctgrDcd = this.ctgrDcd;
			return result;
		},
	},
	watch: {},
	created() {
		this.init();
	},
	mounted() {},
	methods: {
		init() {
			this.getManprdList(this.pageNo);
		},
		//  F: 찜순, S: 별점순
		getManprdList(pageNo) {
			this.pageNo = pageNo;
			this.manprdList = [];

			// api 호출
			this.$store
				.dispatch(`manprd/${ACT_GET_CAMPN_MANPRD_LIST}`, {
					campnId: this.campnId,
					manprdNm: this.searchName,
					ctgrDcd: this.ctgrDcd,
					sortCd: '',
					pageNo: this.pageNo,
					pageSize: this.pageSize,
				})
				.then((res) => {
					tryResCallback(this.tryCount, res, this.getManprdList, this.setManprdList);
				})
				.catch((e) => {
					console.error(e);
				});
		},
		setManprdList(res) {
			if (lengthCheck(res)) {
				this.manprdList = getCheckItems(res).map((i) => {
					return {
						id: i.manprdId,
						image: i.manprdImgFileUrl,
						company: i.brndEngNm,
						title: i.manprdEngNm,
						price: i.manprdPrc,
						rate: i.socreAvg,
						rateCount: i.scoreCnt,
						like: i.favrYn == 'Y',
						likeCount: i.favrCnt,
						myRate: i.myScore,
						desc: i.manprdEngDesc,
						siteUrl: i.ntslSiteUrl,
						isEng : true,
						useCampnDurYn: i.useCampnDurYn == 'Y',
						ntslStrtDt: timestampToDateFormat(i.ntslStrtDt, 'yyyy-MM-dd'),
						ntslEndDt: timestampToDateFormat(i.ntslEndDt, 'yyyy-MM-dd')
					};
				});
			}
			setPaging(this, res);
		},
		// 찜하기
		handleClickLike(idx, type) {
			//const item = type == "F" ? this.manprdList.find(i => i.id == idx) : this.starList.find(i => i.id == idx);
			//this.setFavr(item);
			// item.like = !item.like;
			this.init();
		},
		handleClickModal(idx, type) {
			this.modalId = idx;
			this.modalType = type;
			this.visibleModal = true;
		},
		handleClickModalEvnt(type, score) {
			this.init();
			//if(type == "F") this.setFavr(this.modalItem);
			//else this.setStar({ ...this.modalItem, myRate: score});
		},
	},
};
</script>
