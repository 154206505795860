<template>
	<a href="javascript:" class="products-item" @click.prevent="clickModal(id, type)">
		<div class="products-item-content">
			<div class="products-item-img">
				<span class="products-item-nums" v-if="num !== undefined && num !== null">{{ Number(num) + 1 }}</span>
				<button v-if="like" type="button" class="button-like" @click.prevent.stop="clickLike(id, type)">
					<i class="icon">
						<icon name="product-heart" v-if="item.like" />
						<icon name="product-heart-g" v-else />
					</i>
					<span class="sr-only">찜하기</span>
				</button>
				<img :src="item.image" :alt="item.title" />
			</div>
			<div class="products-item-info">
				<span class="products-item-info__company">{{ item.company }}</span>
				<span class="products-item-info__title">{{ item.title }}</span>
				<span class="products-item-info__price">{{ Number(item.price).toLocaleString() }}</span>
			</div>

			<div v-if="like || rate" class="products-item-footer">
				<dl class="products-item-footer__dl">
					<dt class="sr-only">별점</dt>
					<dd>
						<i class="icon"><icon name="icon-small-star" /></i>
						<span>{{ item.rate }}</span>
						<em>({{ Number(item.rateCount).toLocaleString() }})</em>
					</dd>
				</dl>
				<dl class="products-item-footer__dl">
					<dt class="sr-only">좋아요</dt>
					<dd>
						<i class="icon"><icon name="icon-small-heart" /></i>
						<span>{{ Number(item.likeCount).toLocaleString() }}</span>
					</dd>
				</dl>
			</div>
		</div>
	</a>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_UPDATE_CAMPN_MANPRD_FAVR, ACT_UPDATE_CAMPN_MANPRD_STAR } from '@/store/_act_consts';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_CONFIRM, MUT_SHOW_ALERT } from '@/store/_mut_consts';

import { isSuccess, dateToDateFormat } from '@/assets/js/utils';
import { countDDay } from '@/assets/js/DateUtils';
import Icon from '@/components/common/Icon';
export default {
	name: 'ProdcutsItem',
	props: {
		id: null,
		item: null,
		num: null,
		type: String,
		parentLikeEvntNm: String,
		parentClickEvntNm: String,
		like: {
			type: Boolean,
			default: true,
		},
		rate: {
			type: Boolean,
			default: true,
		},
	},
	components: { Icon },
	data: () => ({}),
	computed: {
		...mapGetters('common', ['isMobile', 'commonCode', 'campn']),
		...mapGetters('auth', ['session', 'isAuth']),
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		clickLike(idx, type) {
			this.setFavr();
			//this.$emit(this.parentLikeEvntNm, idx, type);
		},
		clickModal(idx, type) {
			this.$emit(this.parentClickEvntNm, idx, type);
		},
		chkLogin(title) {
			if (typeof this.session.userId == 'undefined') {
				const param = {
					title: title,
					html: true,
					showCancelButton: false,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
					nofunc: () => {},
				};
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, param);
				return false;
			}

			return true;
		},
		chkCampnDuration() {

			const nowDt = dateToDateFormat(new Date(), 'yyyy-MM-dd') + ' 00:00';
			// 기간 체크
			if (countDDay(this.campn.strtDt, nowDt) < 0 || countDDay(nowDt, this.campn.endDt) < 0) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: `이벤트 기간이 아닙니다.`,
					rightNow: true,
				});
				return false;
			}
			return true;
		},
		// 찜하기 설정
		setFavr() {
			if (!this.chkCampnDuration()) return false;
			if (!this.chkLogin('로그인 상태에서 찜할 수 있습니다. 로그인하시겠습니까?')) return false;

			// api 호출
			this.$store
				.dispatch(`manprd/${ACT_UPDATE_CAMPN_MANPRD_FAVR}`, {
					manprdId: this.item.id,
					params: {
						manprdId: this.item.id,
						score: 0,
						useYn: !this.item.like ? 'Y' : 'N',
					},
				})
				.then((res) => {
					this.getFavrAlter(res);
				})
				.catch((e) => {
					console.error(e);
				});
		},
		getFavrAlter(res) {
			if (isSuccess(res)) {
				this.item.like = !this.item.like;
				let text = this.item.like ? '찜하기 등록되었습니다' : '찜하기 해제되었습니다.';
				//this.$toast.open(text);

				// 마케팅 동의 여부 체크
				if(this.item.like && this.campn.prvcMktAgreYn != 'Y') {
					this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
						title: '찜하기 등록되었습니다. 찜하기 이벤트에 참여하시겠습니까?',
						showCancelButton: true,
						yesfunc: () => {this.$router.push({name:'EventDibs'});},
						nofunc: () => {this.$emit(this.parentLikeEvntNm);}
					});
				} else {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: text,
						yesfunc: () => {},
						rightNow: true,
					});
					this.$emit(this.parentLikeEvntNm);
				}
			} else {
				let text = !this.item.like ? '찜하기 등록 실패 하였습니다.' : '찜하기 해제 실패 하였습니다.';
				if(res && res.result && res.result.number > 900) text = res.result.message;

				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: text,
					yesfunc: () => {},
					rightNow: true,
				});
			}
		},
	},
};
</script>
